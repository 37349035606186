import React, { useState, useEffect, memo, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Dropdown, Modal } from "react-bootstrap";
import { Avatar } from "antd";
import logo from "../../assets/images/logo.png";
import "./TopNavbar.css";
import Notifications from "./Notifications";
import BellIcon from "react-bell-icon";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { BiLockAlt } from "react-icons/bi";
import { BsChatLeftText } from "react-icons/bs";
import PasswordExpired from "../popups/PasswordExpired";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import styled from "styled-components";
import { Button1 } from "../setting/PatientPortalSettings/StyledComponents";
import Messenger from "../Messages";
import { Button } from "react-bootstrap";
import { getSecureMessageList } from "../../StateManagement/Reducers/MessageState";
import { useDispatch } from "react-redux";
import CreateClient from "../popups/CreateClient";
import { BsCheckCircle } from "react-icons/bs";
import CreateNote from "../popups/CreateNote";
// import {
//   setAskProvider,
// } from "../../StateManagement/Reducers/PatientState.js";
const Prompt = styled.div`
  background: red;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
  right: -2px;
  top: -2px;
  // display: ${(props) => (props.display === "0" ? "none" : "block")};
`;

if (localStorage.getItem("token") === "undefined undefined") {
  localStorage.clear();
  localStorage.setItem("logout", true);
}
const TopNavbar = forwardRef((props, ref) => {
  let history = useHistory();
  const profilePicture = useSelector((state) => state.TopNav.profilePicture);
  const NewMsgs = useSelector((state) => state.Message.NewMsgs);
  const Newnote = useSelector((state) => state.Revision.Newnote);
  const location = useLocation();

  const { pathname } = location;
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const [NotifyAlert1, setNotifyAlert1] = useState(false);
  const [NotifyAlert, setNotifyAlert] = useState(false);
  const [username, setusername] = useState("");
  const [useremail, setuseremail] = useState("");
  const [patimg, setpatimg] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [AppointmentNotification, setAppointmentNotification] = useState([]);
  const [countnotify, setCountnotify] = useState("0");
  const [MsgCount, setMsgCount] = useState(0);
  const [notfyReload, setNotfyReload] = useState(false);

  const [show1, setShow1] = useState(false);
  const [proChat, setProChat] = useState(0);
  const [show2, setShow2] = useState(false);
  const [id, setid] = useState("0");
  const [show, setShow] = useState(false);
  const [show4, setShow4] = useState(false);
  const [clientAddIs, setClientAddIs] = useState(false);
  const [clientFail, setClientFail] = useState(false);
  const dispatch = useDispatch();
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  var uid =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "westside");

  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  var testurl = finalurl + "/sites/default/documentimg/";

  // ------------------------------ State and Variables Start--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //

  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShow1 = () => {
    setShow4(true);
    localStorage.removeItem("NewEid");
    localStorage.removeItem("NewEncounter");
    localStorage.removeItem("NewPid");
  };
  const handleClose3 = () => setShow4(false);
  const ClientAdd = (result) => setClientAddIs(result);
  const ClientFailed = (result) => setClientFail(result);
  const successreload = (val) => {
    setAppointmentNotification(val);
  };
  const refreshData = () => {
    const url = window.location.href;
    const arr = url.split("/");
    const item = arr.pop();
    if (item === "patientlist" || (arr[3] === "patient" && arr[4] === "nav")) {
      props.refreshPatient();
    } else {
      history.push("/patient/patientlist");
    }
  };
  const refreshNote = () => {
    const url = window.location.href;
    const arr = url.split("/");
    const item = arr.pop();
    if (item === "clinicalnotes") {
      props.refreshnote();
      window.location.reload();
    } else {
      history.push("/clinicalnotes");
    }
  };
  const handleShowMesg = () => {
    axiosInstance
      .get("/westside/appointment/notification/alert?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        if (DecodeVal.total !== "0") {
          axiosInstance
            .get(
              "/westside/appointment/notification/alert/update?uid=" + user_id
            )
            .then((response) => {})
            .catch((err) => {});
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setNotifyAlert(false);
  };

  const logout = () => {
    axiosInstance
      .post("/westside/updatelogout?uid=" + uid)
      .then((response) => {
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        localStorage.setItem("logout", true);
        window.location.href = "/";
      });
  };

  const gotoProfile = () => {
    history.push("/settings/settingsmyprofile");
  };
  const getData = async () => {
    await axiosInstance
      .get("westside/appointment/notification?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");
        setAppointmentNotification(DecodeVal);
      })
      .catch((err) => {});

    await axiosInstance
      .get("westside/appointment/notification/alert?uid=" + user_id)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "westside");

        if (
          DecodeVal.total !== "0" &&
          DecodeVal.total !== 0 &&
          !props.reqDrop
        ) {
          setNotifyAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await getNotify();

    if (props.reqDrop) {
      setNotifyAlert(false);
    }
  };
  const getNotify = async () => {
    axiosInstance
      .get("/westside/patient/notification/data?type=get&id=0&uid=" + user_id)
      .then((res) => {
        let data = Decrypt_Value(res.data, "westside");
        setCountnotify(data);
        setMsgCount(data.total_count);
        if (data && data["alert"]) {
          setNotifyAlert1(true);
        } else {
          setNotifyAlert1(false);
        }
      })
      .catch(() => {
        setNotifyAlert1(false);
        setCountnotify([]);
      });
  };

  const handlemsgOpen = () => {
    setShow2(true);
    setid("0");
  };
  const handleclose = () => {
    setShow2(false);
  };
  // ------------------------------ Functions----end------------------------------------------------------ //

  // ------------------------------ useEffect----start---------------------------------------------------- //
  useEffect(() => {
    if (Newnote === true) {
      setShow4(true);
    }
  }, [Newnote]);
  useEffect(() => {
    getData();
  }, [notfyReload, props.reqDrop, refresh]);

  useEffect(() => {
    axiosInstance
      .get("/westside/provider?uid=" + user_id)
      .then((response) => {
        const Decrypt_Value_1 = Decrypt_Value(response.data, "westside");
        setpatimg(Decrypt_Value_1.img_url);
        setusername(Decrypt_Value_1.fname + " " + Decrypt_Value_1.lname);
        setuseremail(Decrypt_Value_1.email);
      })
      .catch((err) => console.log(err));
  }, [refresh, pathname]);

  useEffect(() => {
    if (profilePicture !== "") {
      setpatimg(profilePicture);
    }
  }, [profilePicture]);

  useEffect(() => {
    dispatch(getSecureMessageList({ userId: user_id }));
  }, [refresh]);

  // ------------------------------ useEffect----end---------------------------------------------------
  return (
    <>
      <Navbar bg="light" variant="light" className="top-navbar top-nav-fixed">
        <Navbar.Brand as={Link} to="/schedule/calender" className="nav-logo">
          <img
            src={logo}
            className="d-inline-block align-top vozo-logo"
            alt="avalon-ehr-logo"
          />
        </Navbar.Brand>
        <div className="top_nav_display">
          <div>
            <Button
              onClick={handleShow1}
              variant="primary"
              className="save_active top-client-btn"
            >
              Create New Note
            </Button>
          </div>
          <div>
            <Button
              onClick={handleShow}
              variant="primary"
              className="save_active top-client-btn"
            >
              Create New Client
            </Button>
          </div>
          <div className="top-msg-icon">
            {NewMsgs !== 0 && <Prompt />}
            <Button1
              borderRadius="4px"
              padding=" 5.5px 8px"
              backgroundColor="#ffff"
              color="rgba(46, 46, 46, 1)"
              onClick={handlemsgOpen}
            >
              <BsChatLeftText style={{ width: "16", height: "16px" }} />
            </Button1>
          </div>
          <Dropdown
            show={props.reqDrop}
            onToggle={props.DropReq}
            className="top-nav-rem-pad"
          >
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <a
                href="#"
                onClick={handleShowMesg}
                className="top-nav-msg-box flex-center"
              >
                <BellIcon
                  id="appt-inp_bell"
                  stroke="red"
                  width="20"
                  color={
                    NotifyAlert1
                      ? "#f55151"
                      : NotifyAlert
                      ? "#f55151"
                      : "#212121"
                  }
                  active={NotifyAlert || NotifyAlert1}
                  animate={NotifyAlert || NotifyAlert1}
                  className={
                    NotifyAlert1 || NotifyAlert
                      ? "bell-icon1"
                      : !NotifyAlert || !NotifyAlert1
                      ? "bell-icon"
                      : ""
                  }
                />
              </a>
            </Dropdown.Toggle>
            <Dropdown.Menu align="center">
              <div className="dropdown-menu show app-notification for-tab-style ">
                <Notifications
                  appData={AppointmentNotification}
                  profileimage={patimg === null ? "" : testurl + patimg}
                  successreload={successreload}
                  MsgCount={MsgCount}
                  notifyCount={countnotify}
                  refid={props.refid}
                  providerName={username}
                  calRefHandle={props.calRefHandle}
                  show={props.reqDrop}
                  changeDrop={props.DropReq}
                  getNotify={getNotify}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown id="remove_show">
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              {patimg === null || patimg === "" ? (
                <Avatar
                  size={32}
                  className="topNavBarAvat topnav_popavatar top-nav-bg-col"
                >
                  <span className="topNavBarAvatar top-nav-pro-cap">
                    {username.charAt(0).toUpperCase()}
                  </span>
                </Avatar>
              ) : (
                <Avatar
                  size={32}
                  icon={
                    <img
                      src={testurl + patimg}
                      alt="pic-failed"
                      loading="lazy"
                      width="193px"
                      height="130px"
                    />
                  }
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu id="remove_show_1" className="topnav_card_top">
              <div className="dropdown-menu show card_container topnavcard">
                <div className="ArrowUp"></div>
                <div className="card_top topnav_popup_passdisplay">
                  <div>
                    {patimg === null ? (
                      <Avatar
                        size={40}
                        className="topNavBarAvat topnav_popavatar top-nav-bg-col"
                      >
                        <span className="topNavBarAvatar top-nav-drop-item-icon">
                          {username.charAt(0).toUpperCase()}
                        </span>
                      </Avatar>
                    ) : (
                      <Avatar
                        size={25}
                        icon={
                          <img
                            src={testurl + patimg}
                            alt="pic-failed"
                            loading="lazy"
                            width="193px"
                            height="130px"
                          />
                        }
                      />
                    )}
                  </div>
                  <div className="avatarcard_info navpopname">
                    <div className="app_pro_text_wrap aname" title={username}>
                      <span className=" aname" float="left">
                        {username}
                      </span>
                    </div>

                    <div className="app_pro_text_wrap amail" title={useremail}>
                      <span className=" amail" float="left">
                        {useremail}
                      </span>
                    </div>
                  </div>
                  <div
                    className="avatarcard_edit topnav_avatarcard_"
                    onClick={gotoProfile}
                  >
                    Edit
                  </div>
                </div>

                <hr className="hr.horizline"></hr>

                <div className="planpass">
                  <div className="pass  bar topnav_popup_pass plan_pass pass_alt">
                    <div className="lock topnav_popup_passdisplay">
                      <BiLockAlt className="topnav_popup_pass_icon" />
                      <div className=" ico_word" onClick={gotoProfile}>
                        Change Password
                      </div>
                    </div>
                  </div>
                </div>

                <div className="log bar" onClick={logout}>
                  Log out
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Modal
          className="client-popup create-client create_modaltop"
          centered
          show={show}
          onHide={handleClose}
        >
          <CreateClient
            clientShow={handleClose}
            dataFetch={refreshData}
            ClientAdd={ClientAdd}
            ClientFailed={ClientFailed}
          />
        </Modal>
        <Modal
          className="client-popup create-client  create_modaltop create-note"
          centered
          show={show4}
          onHide={handleClose3}
        >
          <CreateNote clientShow={handleClose3} dataFetch={refreshNote} />
        </Modal>
        <Modal className="alert-popup-message" show={clientAddIs}>
          <div className="alert msg alert-success" role="alert">
            <BsCheckCircle className="icons-bs" /> Successfully added...
          </div>
        </Modal>
        <Modal className="alert-popup-message" show={clientFail}>
          <div className="alert msg alert-danger" role="alert">
            <BsCheckCircle className="icons-bs" /> Failed to add client
          </div>
        </Modal>

        <Modal show={show1} onHide={handleClose1}>
          <PasswordExpired close={handleClose1} />
        </Modal>
        {show2 && (
          <Messenger
            show2={show2}
            close={handleclose}
            proChatCount={proChat != "noData" ? proChat : 0}
            id={id}
            providerName={username}
            profileimage={patimg === null ? "" : testurl + patimg}
          />
        )}
      </Navbar>
    </>
  );
});
export default memo(TopNavbar);
